import React from "react";
import "./ourProducts.css";
import arrow from "../../Assets/arrow.png";
import ProductImage from "../../Assets/ourProduct.svg";
import product2 from "../../Assets/investmentProduct2.svg";

const OurProducts = () => {
  return (
    <section className="mx-auto wr_Productsection">
      <h1 className="fw-bold productHeading text-center mt-4">Our Products</h1>

      <div className="productCard mx-auto d-flex justify-content-center align-items-center rounded-2 flex-column flex-md-row mb-3">
        <img
          src={ProductImage}
          alt="Tailored CRM for Overseas Education Advisors"
          className="crmImage"
        />
        <div className="productDescription">
          <ul className="list-unstyled">
            {renderFirstListItem()}
            {renderListItem(
              arrow,
              "Efficiency through dedicated dashboards, offering detailed student profiles and focused interfaces for counselors, associates, and administrators."
            )}
            {renderListItem(
              arrow,
              "Efficiently manage and track student applications from initiation to completion, ensuring a smooth and organized process for counselors and administrators alike."
            )}
            {renderListItem(
              arrow,
              "Enhance productivity through automated tasks like reminders, application status updates, and follow-up management."
            )}
          </ul>
        </div>
      </div>
      <div className="productCard mx-auto d-flex justify-content-center align-items-center rounded-2 flex-column flex-md-row mb-3">
        <div className="productDescription">
          <ul className="list-unstyled">
            {renderSecondListItem()}
            {renderListItem(
              arrow,
              "Our investment portal prioritizes the security and privacy of user data. Through robust encryption techniques and secure authentication protocols."
            )}
            {renderListItem(
              arrow,
              "Users can keep a close eye on their investments with our real-time portfolio tracking feature. Visualize users portfolio's performance with interactive charts and graphs, allowing users to track gains, losses, and overall investment growth over time."
            )}
            {renderListItem(
              arrow,
              "Hassle-free investment with secure payment gateway integration."
            )}
          </ul>
        </div>

        <img
          src={product2}
          alt="Tailored CRM for Overseas Education Advisors"
          className="crmImage"
        />
      </div>
    </section>
  );
};

const renderFirstListItem = () => (
  <li>
    <h2 className="crmSubheading">
      Tailored CRM for Overseas Education Advisors
    </h2>
    <p className="productContent">
      Our study abroad consultancy CRM is meticulously designed to empower
      educational consultants, counselors, associates, and administrators with
      essential tools for superior student support, application management, and
      seamless collaboration.
    </p>
  </li>
);

const renderSecondListItem = () => (
  <li>
    <h2 className="crmSubheading">Investment Portal</h2>
    <p className="productContent">
      A dynamic platform designed to revolutionize the way investors engage with
      financial markets. Seamlessly blending user-friendly interface design with
      robust backend architecture.
    </p>
  </li>
);

const renderListItem = (image, text) => (
  <li className="d-flex py-3">
    <img src={image} alt="arrow" className="me-3" />
    <span>{text}</span>
  </li>
);

export default OurProducts;
