import React, { useEffect } from "react";
import aboutImage from "../../Assets/aboutSectionImage.jpg";
import "./aboutUs.css";
import AOS from "aos";

function AboutUs() {
  useEffect(() => {
    const isDesktop = window.innerWidth > 768; // Adjust the breakpoint as needed

    if (isDesktop) {
      AOS.init({
        offset: 0,
        duration: 500,
        easing: "ease-in-out",
      });
    }
  }, []);

  return (
    <div className="wr_aboutSection pt-4">
      <h1 className="aboutUsHeading text-center fw-bold">About Us</h1>
      <div className="mx-auto d-flex flex-column flex-md-row align-items-center justify-content-center p-2">
        <img
          src={aboutImage}
          alt="Company About"
          className="aboutImage"
          data-aos={window.innerWidth > 768 ? "fade-down" : ""}
        />
        <p
          className="aboutDescription"
          data-aos={window.innerWidth > 768 ? "fade-up" : ""}
        >
          We're a dynamic startup specializing in CRM solutions and MERN stack
          development. Our team of creative developers embraces the MVP process,
          ensuring efficient and top-notch solutions. Committed to excellence,
          we transform ideas into robust software. Whether it's enhancing CRM
          capabilities or MERN stack development, we craft solutions that
          consistently exceed expectations. Join us on this exciting journey as
          we redefine possibilities in the digital realm.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
