import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import phone from "../../Assets/phone.png";
import email from "../../Assets/email.png";
import axios from "axios";
import config from "../../boot/config.json";
import "./contactForm.css";

function ContactForm() {
  const [error, setError] = useState("");
  const [contactDetails, setContactDetails] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      const numericOnly = value.replace(/\D/g, "");
      setContactDetails({ ...contactDetails, phone: numericOnly });
    } else {
      setContactDetails({ ...contactDetails, [name]: value });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!contactDetails.name) {
      setError("Please enter your name");
    } else if (!contactDetails.email) {
      setError("Please enter email address");
    } else if (!contactDetails.phone) {
      setError("Please enter your mobile number");
    }

    try {
      axios
        .post(`${config.baseUrl}/oneVillaContactUs`, contactDetails)
        .then((response) => {
          setError("");
          setContactDetails({
            name: "",
            email: "",
            phone: "",
            message: "",
          }).catch((error) => {
            console.log(error);
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  const inputClass = "m-0 mb-3";
  const submitButtonClasses =
    "mx-auto contactSubmitButton rounded-3 border-0 fw-bold text-white";

  return (
    <div className="mt-3 wr_contact">
      <h1 className="formHeading fw-bold text-center">Say Hello</h1>

      <div className="d-flex flex-column flex-md-row m-2 mx-auto justify-content-between wr_contactSection">
        <div className="wr_form me-md-2">
          <div className="form">
            <Form onSubmit={handleSubmit}>
              <Form.Group className={inputClass} controlId="name">
                <FloatingLabel label="Full Name">
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="name"
                    value={contactDetails.name}
                    onChange={handleChange}
                  />
                </FloatingLabel>
                {error && error === "Please enter your name" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className={inputClass} controlId="email">
                <FloatingLabel label="Email Address">
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={contactDetails.email}
                    onChange={handleChange}
                  />
                </FloatingLabel>
                {error && error === "Please enter email address" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className={inputClass} controlId="phone">
                <FloatingLabel label="Mobile Number">
                  <Form.Control
                    type="tel"
                    maxLength={10}
                    name="phone"
                    value={contactDetails.phone}
                    placeholder="Mobile Number"
                    onChange={handleChange}
                  />
                </FloatingLabel>
                {error && error === "Please enter your mobile number" ? (
                  <p className="text-danger">{error}</p>
                ) : (
                  ""
                )}
              </Form.Group>

              <Form.Group className={inputClass} controlId="message">
                <FloatingLabel label="Message">
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={contactDetails.message}
                    className="messagetextArea"
                    placeholder="Message"
                    style={{ height: "150px" }}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </Form.Group>

              <button type="submit" className={submitButtonClasses}>
                Submit
              </button>
            </Form>
          </div>
        </div>
        <div className="contactInformation pt-5 px-4 px-lg-3">
          <p className="contactTagLine text-center">
            Got an idea? Let's discuss over a cup of coffee and turn <br />
            your vision into reality.
          </p>
          <div className="d-flex align-items-center justify-content-start">
            <img src={phone} alt="phoneicon" className="me-3" />
            <p>+91 9391101403 </p>
          </div>
          <div className="d-flex align-items-center justify-content-start">
            <img src={email} alt="mailIcon" className="me-3" />
            <a
              className="text-black text-decoration-none mt-1"
              href="mailto:info@svsrco.com"
            >
              <p>info@svsrco.com</p>
            </a>
          </div>
        </div>
      </div>

      <div className="copyright d-flex flex-column flex-lg-row justify-content-center align-items-center">
        <p className="m-0 me-lg-4 fw-light">
          © 2024 SVSR Communications Private Limited. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default ContactForm;
