import React from "react";
import development from "../../Assets/development.png";
import maintenance from "../../Assets/maintenance.png";
import mvp from "../../Assets/mvp.png";
import "./services.css";

function Services() {
  const services = [
    {
      name: "Development",
      image: development,
      description:
        "At SVSR, we transform your ideas into a powerful software solutions. Our development team uses the cutting-edge technologies to create standout applications.",
    },
    {
      name: "Maintenance",
      image: maintenance,
      description:
        "We provide continuous support to keep your digital presence running Smooth and reliable. We offer maintenance services ensure your applications are secure, up-to-date, and perform at their best.",
    },
    {
      name: "MVP Development",
      image: mvp,
      description:
        "Embarking on a new venture? Our MVP (Minimum Viable Product) development services help you test ideas in the market fast. Partner with us to turn concepts into functional MVPs for future success.",
    },
  ];

  return (
    <>
      <h1 className="fw-bold py-4 text-center">Our Services</h1>
      <div className="d-flex flex-column flex-md-row servicesCards justify-content-center ">
        {services.map((service, index) => (
          <div className="serviceSingleCard position-relative" key={index}>
            <div className="imgbox w-100 h-100">
              <img src={service.image} alt="svsr sevice Card" className="" />
              <h2 className="text-center">{service.name} </h2>
            </div>
            <div className="content">
              <p>{service.description}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default Services;
