import React, { createContext, useState, useEffect } from "react";
import PathConfig from "./Routes/pathConfig";
import Navbar from "./Routes/Navbar";
import { useLocation } from "react-router-dom";
import AlertDismissible from "./Components/Utils/Alert";

export const AuthContext = createContext();

function App() {
  const [tokenData, setTokenData] = useState({
    token: "",
    roleId: "",
    userId: "",
    agencyId: "",
    alertMsg: "",
    alertValue: false,
    alertStatus: true,
    profileStatus: false,
    blogSearch: "",
  });
  const [pageNum, setPageNum] = useState(1);
  const [univPage, setUnivPage] = useState(1);
  const [paginNum, setPaginNum] = useState(1);

  const handleLogout = () => {
    sessionStorage.removeItem("svsrJWT");
    setTokenData((prevState) => ({
      ...prevState,
      token: "",
      roleId: "",
      userId: "",
      agencyId: "",
      alertMsg: "",
      alertValue: false,
      alertStatus: true,
      blogSearch: "",
    }));
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem("svsrJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setTokenData(authData);
    }
  }, []);

  const logout = () => {
    return tokenData ? (
      <div onClick={handleLogout}>Logout</div>
    ) : (
      <div style={{ display: "none" }}></div>
    );
  };

  return (
    <AuthContext.Provider
      value={{
        tokenData,
        handleLogout,
        logout,
        setTokenData,
        univPage,
        setUnivPage,
        pageNum,
        setPageNum,
        paginNum,
        setPaginNum,
      }}
    >
      <div>
        <Navbar />
        <PathConfig />
        <div
          className={`position-fixed   ${
            tokenData.alertValue ? "animateAlert " : "hideAlert"
          }`}
        >
          {tokenData.alertValue && (
            <AlertDismissible
              message={tokenData.alertMsg}
              value={tokenData.alertStatus}
            />
          )}
        </div>
      </div>
    </AuthContext.Provider>
  );
}

export default App;
