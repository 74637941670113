import React from "react";
import html from "../../Assets/html.png";
import css from "../../Assets/css.png";
import js from "../../Assets/javascript.png";
import react from "../../Assets/reacticon.png";
import vue from "../../Assets/vuejs.png";
import mongodb from "../../Assets/mongodbIcon.png";
import express from "../../Assets/expressjs.png";
import bootstrap from "../../Assets/bootstrap.png";
import node from "../../Assets/nodejs.png";
import figma from "../../Assets/figma.png";
import others from "../../Assets/others.png";

import "./technologies.css";

function Technologies() {
  const technologies = [
    {
      icon: react,
      name: "React Js",
    },
    { icon: react, name: "React Native" },

    {
      icon: node,
      name: "NodeJs",
    },
    {
      icon: express,
      name: "Express Js",
    },
    {
      icon: mongodb,
      name: "MongoDB",
    },
    {
      icon: vue,
      name: "Vue Js",
    },

    {
      icon: html,
      name: "HTML",
    },
    {
      icon: css,
      name: "CSS",
    },
    {
      icon: js,
      name: "JavaScript",
    },
    {
      icon: bootstrap,
      name: "Bootstrap",
    },
    {
      icon: figma,
      name: "Figma",
    },
    {
      icon: others,
      name: "Others",
    },
  ];
  return (
    <section className="text-center py-4 techSection w-100">
      <div>
        <h1 className="techHeading fw-bold mb-4 text-center">
          Technologies We Use
        </h1>
      </div>
      <div className="d-grid wr_tech grid-container">
        {technologies.map((tech, index) => (
          <div
            key={index}
            className=" techNames d-flex bg-white justify-content-start align-items-center rounded-3"
          >
            <img
              src={tech.icon}
              alt="technologies icon"
              className="techIcon pe-3"
            />
            <span className="technologyName">{tech.name}</span>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Technologies;
