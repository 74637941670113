import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import AOS from "aos";
import WhyChooseUsImage from "../../Assets/whyChooseUsImage.svg";
import "aos/dist/aos.css";
import "./whyChooseUs.css";

function WhyChooseUs() {
  const whyChooseUsData = [
    {
      name: "Intuitive User Experience",
      description: `Experience seamless navigation and user-friendly interfaces that prioritize simplicity. Our team ensures that interacting with your website or application is not only efficient but also enjoyable for your users.`,
    },
    {
      name: "Client-Centric Approach",
      description: `Our client-centric approach places your needs at the forefront. We are dedicated to understanding your requirements and delivering solutions that not only meet but exceed your expectations.`,
    },
    {
      name: "Tailored Solutions",
      description: `Crafting solutions tailored to your business needs is our forte. Leveraging our expertise in React, NodeJs, and MongoDB, we create customized web solutions that align perfectly with your unique goals and requirements.`,
    },
    {
      name: "MVP Development",
      description: `Accelerate your project's time-to-market with our expertise in developing MVPs. We understand the importance of quickly validating your ideas, and our skilled team ensures the rapid and efficient delivery of MVPs to kickstart your project.`,
    },
  ];

  const [animatedCards, setAnimatedCards] = useState([]);
  const wmsoRef = useRef(null);

  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 200,
      easing: "ease-in-out",
      delay: 100,
    });

    const isInViewport = () => {
      const rect = wmsoRef.current.getBoundingClientRect();
      return rect.top < window.innerHeight && rect.bottom >= 0;
    };

    const handleScroll = () => {
      if (isInViewport()) {
        setAnimatedCards([0, 1, 2, 3]);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="py-5" ref={wmsoRef}>
      <h1 className="fw-bold whChooseUsHeading pb-3 text-center">
        What Sets Us Apart?
      </h1>
      <div className="d-lg-flex flex-wrap wr_whychooseus justify-content-center align-items-center">
        <div className="wr_cards mx-auto mx-xl-0 d-flex flex-wrap justify-content-center align-items-center">
          {whyChooseUsData.map((item, index) => (
            <Card
              key={index}
              className={`main_card ${
                animatedCards.includes(index) ? "animated" : ""
              }`}
              data-aos={animatedCards.includes(index) ? "custom" : ""}
            >
              <Card.Body>
                <Card.Title className="text-center SubHeading ">
                  {item.name}
                </Card.Title>
                <Card.Text className="whyChooseUsDescription">
                  {item.description}
                </Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <img
          src={WhyChooseUsImage}
          alt="why choose us"
          className="whyChooseUsImage d-none d-xl-block"
        />
      </div>
    </div>
  );
}

export default WhyChooseUs;
