import React, { useEffect, useState, useMemo, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import ReactPaginate from "react-paginate";
import { FaSearch } from "react-icons/fa";
import { Link } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import "./myBlogs.css"; // Ensure this is the path to your CSS file
import config from "../../boot/config.json";
import { AuthContext } from "../../App";
import HelmetWrapper from "../../HelmetWrapper";
import { RxCross2 } from "react-icons/rx";

function MyBlogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const { tokenData } = useContext(AuthContext);

  const [myBlogsState, setMyBlogsStates] = useState({
    statusBar: { publish: true, adminApproval: "pending", page: 0 },
    tableIndicator: "",
  });

  const [pageIndex, setPageIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchStat, setSearchStat] = useState(false);
  const [searchStatPage, setSearchStatPage] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!tokenData || !tokenData.token) {
      console.error("Token is missing or invalid");
      setLoading(false);
      return;
    }

    const axiosInstance = axios.create({
      baseURL: `${config.baseUrl}/Blogs`,
      headers: {
        Authorization: tokenData.token,
      },
    });

    let tableDataExists = JSON.parse(sessionStorage.getItem("statusBar"));
    let status = {};
    if (tableDataExists) {
      status = tableDataExists;
    } else {
      status = myBlogsState.statusBar;
      status.page = 0;
    }

    if (searchStatPage) {
      status.page = 0;
    }
    const getUserData = () => {
      // console.log("pagination change", searchTerm);

      axiosInstance
        .post("/StatusBasedData", { ...status, searchTerm })
        .then((response) => {
          setBlogs(response.data.data);
          setPageCount(response.data.totalPages);
          sessionStorage.setItem("statusBar", JSON.stringify(status));
          setPageIndex(status.page);
          setLoading(false);
          setMyBlogsStates((prev) => ({
            ...prev,
            tableIndicator: JSON.parse(sessionStorage.getItem("statusBar"))
              .adminApproval,
          }));
        })
        .catch((error) => {
          console.error("There was an error fetching the blogs!", error);
          setLoading(false);
        });
    };

    getUserData();
  }, [tokenData, myBlogsState.statusBar, searchStat]);

  const handleStatusUpdate = (status, selected) => {
    if (!tokenData || !tokenData.token) {
      console.error("Token is missing or invalid");
      return;
    }

    const axiosInstance = axios.create({
      baseURL: `${config.baseUrl}/Blogs`,
      headers: {
        Authorization: tokenData.token,
      },
    });
    status.page = selected;
    // console.log("search change", searchTerm);
    // console.log("page", status.page);

    axiosInstance
      .post("/StatusBasedData", { ...status, searchTerm })
      .then((response) => {
        setBlogs(response.data.data);
        sessionStorage.setItem("statusBar", JSON.stringify(status));
        setPageCount(response.data.totalPages);
        setLoading(false);
        setPageIndex(selected);
        setMyBlogsStates((prev) => ({
          ...prev,
          tableIndicator: JSON.parse(sessionStorage.getItem("statusBar"))
            .adminApproval,
        }));
      })
      .catch((error) => {
        console.error("There was an error fetching the blogs!", error);
        setLoading(false);
      });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearchBtn = (e, status) => {
    if (status === "searchButton") {
      setSearchStat(!searchStat);
      setSearchStatPage(true);
    } else if (e.key === "Enter") {
      setSearchStat(!searchStat);
      setSearchStatPage(true);
    } else if (status === "closeSearch") {
      setSearchTerm("");
      setSearchStat(!searchStat);
      setSearchStatPage(false);
    }
    if (searchTerm === "") {
      setSearchStatPage(false);
    }
  };

  function formatDate(isoDateStr) {
    const date = new Date(isoDateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

  function urlEncoded(title) {
    return title
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, "-")
      .replace(/^-+|-+$/g, "");
  }

  const data = useMemo(() => blogs, [blogs]);

  const columns = useMemo(() => {
    const cols = [
      {
        Header: "S.No",
        accessor: (row, i) => i + 1,
      },
      {
        Header: "Name of the Blog",
        accessor: "blogName",
        Cell: ({ value }) => (
          <div title={value}>
            {value && value.length > 30 ? value.slice(0, 30) + "..." : value}
          </div>
        ),
      },
      {
        Header: "Author Name",
        accessor: "author",
      },

      {
        Header: "Version",
        accessor: "version",
        Cell: ({ value }) => (
          <span className="version mx-2 px-2 text-white rounded-circle">
            {value}
          </span>
        ),
      },
      {
        Header: "Status",
        accessor: "adminApproval",
        Cell: ({ value }) => (
          <span style={{ textTransform: "capitalize" }}>{value}</span>
        ),
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ value }) => <span>{formatDate(value)}</span>,
      },
      {
        Header: "View",
        Cell: ({ row }) => (
          <div
            title="view blog"
            className="viewIcon"
            onClick={() => {
              sessionStorage.setItem(
                "location",
                JSON.stringify(window.location.pathname)
              );
              navigate(
                `/blog/${urlEncoded(
                  row.original.blogName
                )}/${row.original.scBlogId.slice(4, 10)}`
              );
            }}
          >
            <IoEye className="fs-4 ms-2" />
          </div>
        ),
      },
    ];

    if (tokenData.roleId === "User" || tokenData.roleId === "Guest") {
      return cols.filter((col) => col.Header !== "Author Name");
    } else if (tokenData.roleId === "Admin") {
      return cols.filter((col) => col.Header !== "Version");
    }

    return cols;
  }, [tokenData.roleId]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      pageCount,
      manualPagination: true,
      state: { pageIndex },
    },
    useGlobalFilter,
    usePagination
  );

  const handlePageChange = (selected) => {
    setPageIndex(selected.selected);
    gotoPage(selected.selected);
    const status = JSON.parse(sessionStorage.getItem("statusBar"));
    handleStatusUpdate(status, selected.selected);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  const getColorClass = (indicator) => {
    switch (indicator) {
      case "approved":
        return "success";
      case "pending":
        return "warning";
      case "working":
        return "info";
      default:
        return "danger";
    }
  };
  const handleRowClick = (row) => {
    // console.log("row.original", row.original);
    navigate(
      `/blog/${urlEncoded(row.original.blogName)}/${row.original.scBlogId.slice(
        4,
        10
      )}`
    );
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <HelmetWrapper
        title="My Blogs | SVSRCO, SVSR Communications"
        description="Manage your blogs on SVSRCO. View, edit, and delete your posts easily. Keep track of your content and stay engaged with your audience."
        canonicalUrl="https://www.svsrco.com/protected/myBlogs"
      />
      <div className="d-flex flex-column justify-content-center align-items-center ">
        <StatusBar onStatusUpdate={handleStatusUpdate} className="mx-2 " />
        {tokenData.token &&
        (tokenData.roleId === "User" ||
          tokenData.roleId === "Admin" ||
          tokenData.roleId === "Guest") ? (
          <Link
            to="/protected/addPost"
            title="Add Blogs"
            className="position-fixed addUser px-2 py-2 rounded-2 text-dark my-4"
          >
            Add Blogs
          </Link>
        ) : (
          <></>
        )}
        <div className="d-flex position-relative">
          <input
            value={searchTerm}
            onChange={handleSearchChange}
            placeholder="Search blogs..."
            className="searchBarRole mt-5 rounded-5"
            onKeyPress={(e) => handleSearchBtn(e)}
          />
          {searchTerm && searchTerm.length > 0 && (
            <button
              className=" border-0 searchDelete position-absolute mt-5 rounded-circle"
              onClick={(e) => handleSearchBtn(e, "closeSearch")}
            >
              <RxCross2 />
            </button>
          )}
          <button
            className="border-0 searchIcon position-absolute end-0 mt-5"
            title="search"
            onClick={(e) => handleSearchBtn(e, "searchButton")}
          >
            <FaSearch className="text-white" />
          </button>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-center align-items-center tableComponentWrapper">
        <table
          {...getTableProps()}
          className="position-relative tableComponent  "
        >
          <span
            className={`bg-${getColorClass(
              myBlogsState.tableIndicator
            )} text-white p-2  rounded-1 position-absolute  searchStatus`}
          >
            {myBlogsState.tableIndicator === "working"
              ? "DRAFTS"
              : myBlogsState.tableIndicator === "rejected"
              ? "REVIEW"
              : myBlogsState.tableIndicator.toUpperCase()}
          </span>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => handleRowClick(row)}
                  className="rowClick"
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
        {pageCount > 1 && (
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            activeClassName={"active"}
            forcePage={pageIndex} // Ensure the pagination component highlights the correct page
          />
        )}
      </div>
    </div>
  );
}

export default MyBlogs;

function StatusBar({ onStatusUpdate }) {
  return (
    <div className="status-barBlogs d-flex justify-content-center p-2 ">
      <div
        className="status-optionBlogs bg-success text-white"
        title="Approved"
        onClick={
          () => onStatusUpdate({ publish: true, adminApproval: "approved" }, 0) // Reset page to 0
        }
      >
        Approved
      </div>
      <div
        className="status-optionBlogs bg-danger text-white"
        title="Review"
        onClick={
          () => onStatusUpdate({ publish: false, adminApproval: "rejected" }, 0) // Reset page to 0
        }
      >
        Review
      </div>
      <div
        className="status-optionBlogs bg-warning text-dark"
        title="Pending"
        onClick={
          () => onStatusUpdate({ publish: false, adminApproval: "pending" }, 0) // Reset page to 0
        }
      >
        Pending
      </div>
      <div
        className="status-optionBlogs bg-primary text-white"
        title="Drafts"
        onClick={
          () => onStatusUpdate({ publish: false, adminApproval: "working" }, 0) // Reset page to 0
        }
      >
        Drafts
      </div>
    </div>
  );
}
