import "./clients.css";

import confluenceLogo from "../../Assets/MainLogo.db6ddce6dc46b0d49c0f.png";
import oneVillaLogo from "../../Assets/onevilla.jpg";
import sprLogo from "../../Assets/spr9.png";
import vidyauni from "../../Assets/vidyauni.png";
import jaikisan from "../../Assets/jaikisan.jpg";
import kaktiya from "../../Assets/kakatiya.jpg";

const imagesList = [
  { logo: confluenceLogo, weblink: "www.confluenceedu.com" },
  { logo: oneVillaLogo, weblink: "www.onevilla.co" },
  { logo: vidyauni, weblink: "www.vidyauni.com" },
  { logo: jaikisan, weblink: "www.jaikisan.co" },
  { logo: kaktiya, weblink: "www.kakatiyacapital.com" },
  { logo: sprLogo, weblink: "spreddyr.com" },
];

function Clients() {
  return (
    <div>
      <h1 className="py-3 fw-bold text-center">Our Clients</h1>

      <div className="imagesBlock d-grid grid-container mx-auto">
        {imagesList.map((singleSite, index) => (
          <a
            key={index}
            href={`https://${singleSite.weblink}`}
            target="_blank"
            rel="noopener noreferrer"
            className=""
          >
            <img
              src={singleSite.logo}
              alt="client company "
              className=" clientLogo "
            />
          </a>
        ))}
      </div>
    </div>
  );
}

export default Clients;
