import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { FaArrowUp } from "react-icons/fa6";

import "./landingPage.css";

const LandingPage = () => {
  const [stickyClass, setStickyClass] = useState("");
  const [scrollToTop, setScrollToTop] = useState("");

  const location = useLocation();

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    const scrollY = document.documentElement.scrollTop;
    const isPageScrolled = scrollY > 100;

    setStickyClass(
      isPageScrolled ? "sticky-nav position-fixed top-0 start-0 bg-white " : ""
    );

    setScrollToTop(isPageScrolled);
  };

  return (
    <section className="wr_home position-relative">
      <div className="headingBlock position-absolute text-center text-md-start">
        <div className="d-flex justify-content-center flex-column">
          <h1 className="lpHeading">
            One Stop Solution for<span className="d-sm-none"> Product</span>
          </h1>
          <div className="d-md-flex">
            <h1 className="lpHeading me-3 d-none d-sm-block">Product</h1>
            <div className="words overflow-hidden text-center text-md-start">
              <span className="d-block">Development</span>
              <span className="d-block">Maintenance</span>
            </div>
          </div>
        </div>

        <p className="lpTagLine text-white fw-bold">
          Revolutionizing Businesses through Cutting-Edge IT Solutions and
          Seamless Development Expertise.
        </p>
        <a href="#contact">
          <button className="lpButton fw-bold border-0">Get In Touch</button>
        </a>
      </div>

      <a
        className={`scrollToTop position-fixed bottom-0 end-0 text-white fs-5 justify-content-center align-items-center rounded-circle m-2${
          scrollToTop ? " d-flex" : " d-none"
        }`}
        href="#home"
      >
        <FaArrowUp />
      </a>
    </section>
  );
};

export default LandingPage;
