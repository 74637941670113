import React from "react";
import Error from "../../Assets/errorpageNotFound.jpg";
import "./error404.css";
import { useNavigate } from "react-router-dom";

function Error404() {
  const navigate = useNavigate();
  return (
    <div className="underMaintenanceWrapper d-flex flex-column justify-content-center align-items-center">
      <div className="maintanenceImg d-flex justify-content-center align-items-center">
        <img src={Error} alt="underworking" />
      </div>
      <div className="w-75 text-center maintenanceText">
        <h2>GO BACK TO HOME</h2>
        <button
          className="border-0 px-3 py-2 mt-3 bg-success text-white rounded-1"
          onClick={() => navigate("/")}
        >
          Home
        </button>
      </div>
    </div>
  );
}

export default Error404;
