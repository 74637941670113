import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

function Faq() {
  const [activeKey, setActiveKey] = useState(null);

  const faqs = [
    {
      question: `What is our process for resolving technical issues and providing support?`,
      ans: `Our support process involves a systematic analysis, diagnostics, and collaborative troubleshooting to ensure prompt and effective issue resolution, minimizing downtime.`,
    },
    {
      question: `How do we handle changes or additions to project requirements?`,
      ans: `We adopt a flexible approach, accommodating changes or additions to project requirements through open communication, impact assessment, and collaborative adjustments to the project plan.`,
    },
    {
      question: `How do we ensure compliance with industry regulations and standards?`,
      ans: `Our processes adhere to industry regulations, and we regularly update our practices to comply with the latest standards, ensuring a secure and compliant solution.`,
    },
    {
      question: `How do we manage and prioritize project timelines to ensure timely delivery without compromising quality?`,
      ans: `We use project management tools, set clear timelines, and regularly assess progress. Our approach involves balancing efficiency with quality, and we proactively address any challenges that may arise during project execution.`,
    },
  ];

  const handleAccordionClick = (eventKey) => {
    setActiveKey(activeKey === eventKey ? null : eventKey);
  };

  return (
    <div className="pb-5">
      <h1 className="fw-bold pt-5 pb-3 text-center">
        Let's Clear Up Some Doubts
      </h1>
      <div className="accordianContainer mx-auto">
        <Accordion activeKey={activeKey} onSelect={handleAccordionClick}>
          {faqs.map((faq, index) => (
            <Accordion.Item
              key={index}
              eventKey={index + 1}
              className="border-0"
            >
              <Accordion.Header>{faq.question}</Accordion.Header>

              <Accordion.Body>{faq.ans}</Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>
    </div>
  );
}

export default Faq;
