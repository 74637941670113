import React, { useContext } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import TechBlogs from "../Components/TechBlogs/TechBlogs";
import TechBlogEditor from "../Components/TechBlogsEditor/TechBlogEditor";
import TechBlogPage from "../Components/TechBlogs/TechBlogPage";
import CareerPage from "../Components/Jobs/CareerPage";
import CareerDescription from "../Components/Jobs/CareerDescription";
import ApplicationForm from "../Components/Jobs/ApplicationForm";
import Login from "../Components/LoginPage/Login";
import JobEditor from "../Components/JobEditor/JobEditor";
import MyBlogs from "../Components/MyBlogs/MyBlogs";
import Users from "../Components/Users/Users";
import AddUser from "../Components/Users/AddUser";
import UserProfile from "../Components/Users/UserProfile";
import Error404 from "../Components/ErrorPage/Error404";
import { AuthContext } from "../App";

import Home from "../Pages/Home";

function PrivateRoutes() {
  const { tokenData } = useContext(AuthContext);
  return tokenData.token ? <Outlet /> : <Navigate to="/blogs" />;
}

function PathConfig() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/blogs" element={<TechBlogs />} />
      <Route path="/admin" element={<Login />} />
      <Route path="/careers" element={<CareerPage />} />
      <Route path="/blog/:title/:id" element={<TechBlogPage />} />
      <Route path="/careers/:title/:jobId" element={<CareerDescription />} />
      <Route path="/applylink/:title/:jobId" element={<ApplicationForm />} />

      <Route path="/protected" element={<PrivateRoutes />}>
        <Route index element={<Navigate to="/protected/addPost" />} />
        <Route path="/protected/addPost" element={<TechBlogEditor />} />
        <Route path="/protected/addJob" element={<JobEditor />} />
        <Route path="/protected/myBlogs" element={<MyBlogs />} />
        <Route path="/protected/Users" element={<Users />} />
        <Route path="/protected/addUsers" element={<AddUser />} />
        <Route
          path="/protected/UserProfile/:userId"
          element={<UserProfile />}
        />

        {/*
            <Route path="/protected/addJob" element={<JobEditor />} /> */}
      </Route>
      {/* <Route path="/:topic/*" element={<FarmersBlogs />} /> */}
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
}

export default PathConfig;
