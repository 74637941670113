import React from "react";
import "./benefitsOfChoosingUs.css";
import benefitsImage from "../../Assets/benefitsOfChooseUs.svg";

function BenefitsofChoosingUs() {
  return (
    <div className="benefitsMainBlock mx-auto">
      <h1 className="fw-bold pb-3 text-center">Benefits of Choosing Us</h1>

      <div className="d-flex flex-column flex-lg-row benefitsMainContentBlock justify-content-center align-items-center">
        <img
          src={benefitsImage}
          alt="Benefits Illustration"
          className="benefitsImage me-md-5"
        />
        <div className="ms-md-5">
          <h3 className="subHeading">Transparency </h3>
          <p className="mainContentpara">
            At the core of our values is a commitment to transparency. We
            believe in clarity and honesty throughout our business practices.
            This commitment extends beyond just pricing, ensuring that you have
            a clear understanding of the value you receive for your investment.
          </p>
          <h3 className="subHeading">Timely Delivery</h3>
          <p className="mainContentpara">
            we prioritize timely project delivery through meticulous project
            management. Our approach includes Agile methodologies, transparent
            communication, skilled project managers, and a commitment to
            deadline adherence.
          </p>
          <h3 className="subHeading">Continuous Support and Maintenance</h3>
          <p className="mainContentpara">
            Beyond project completion,we provide continuous support and
            maintenance services. Our proactive monitoring, security updates,
            scalable support packages, and rapid issue resolution ensure the
            ongoing stability, security, and scalability of your IT solutions.
          </p>
        </div>
      </div>
    </div>
  );
}

export default BenefitsofChoosingUs;
