import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import config from "../../boot/config.json";
import "./techBlogs.css";
// import BlogBox from "../../Blogpage/BlogBox";
// import MobileBanner from "../../Assets/organicFarming.webp";
import { GiPin } from "react-icons/gi";
import { RxDrawingPin } from "react-icons/rx";
import { MdDelete } from "react-icons/md";
import { AuthContext } from "../../App";
import ReactPaginate from "react-paginate";
import Blogs from "./Blogs/Blogs";
import farmersPlaceholder from "../../Assets/HomePage Banner Image.jpg";

function TechBlogs({ draftsMode }) {
  const [blogs, setBlogs] = useState([]);
  const [fav, setFav] = useState(false);
  const { tokenData, setPageNum, pageNum, setPaginNum, paginNum } =
    useContext(AuthContext);
  const [favCount, setfavCount] = useState(0);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [currentPage, setCurrentPage] = useState(pageNum);
  const [len, setLen] = useState(1);
  const [search, setSearchBlogs] = useState(tokenData.blogSearch);
  const [paginationStat, setPaginationStat] = useState(!!tokenData.blogSearch);

  const navigate = useNavigate();

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Blogs`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let page = queryParams.get("page");
  page = JSON.parse(sessionStorage.getItem("currentPage")) || 1;

  const handleSearchChange = (value) => {
    setSearchBlogs(value);
  };

  useEffect(() => {
    setPaginationStat(search !== "");
  }, [search]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("paginationStat", paginationStat);
      try {
        let response;
        if (draftsMode) {
          response = await axiosInstance.get(`/getDrafts?page=${paginNum}`);
          // navigate(`?page=${paginNum}`);
        } else if (tokenData.blogSearch) {
          if (tokenData.blogSearch && paginationStat) {
          } else if (tokenData.blogSearch) {
            page = 1;
            setCurrentPage(() => 1);
            sessionStorage.setItem("currentPage", 1);
          }
          response = await axios.get(
            `${config.baseUrl}/Blogs/getAllBlogs?page=${parseInt(
              page
            )}&search=${tokenData.blogSearch}`
          );
        } else {
          // setCurrentPage(page);
          if (!paginationStat) {
            page = 1;
            sessionStorage.setItem("currentPage", page);
            setCurrentPage(() => page);
          }
          response = await axios.get(
            `${config.baseUrl}/Blogs/getAllBlogs?page=${parseInt(page)}`
          );
          setCurrentPage(() => page);
          setfavCount(response.data.favCount);
        }

        setBlogs(response.data.data);
        setLen(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // setUnivPage(currentPage);
    fetchData();
  }, [deleteStatus, fav, draftsMode, currentPage, search]);

  useEffect(() => {
    // navigate(`?page=${pageNum}`);
    setCurrentPage(pageNum);
    sessionStorage.setItem("currentPage", pageNum);
  }, [pageNum]);

  // useEffect(() => {
  //   if (location.pathname === "/protected/drafts") {
  //   }
  // }, [location.pathname, setPageNum]);

  // useEffect(() => {
  //   navigate(`/destination?page=${univPage}`);
  //   setCurrentPage(univPage);
  // }, [currentPage]);

  let pageCount = len;
  const handlePageClick = async (selectedPage) => {
    if (draftsMode) {
      // navigate(`?page=${selectedPage.selected + 1}`);
      setPaginNum(selectedPage.selected + 1);
    } else {
      // navigate(`?page=${selectedPage.selected + 1}`);
      setCurrentPage(selectedPage.selected + 1);
      sessionStorage.setItem("currentPage", selectedPage.selected + 1);
      setPageNum(selectedPage.selected + 1);
      setPaginationStat(true);
    }
  };

  const deleteBlog = async (e, id) => {
    try {
      const response = await axiosInstance.delete(`/deleteBlog/${id}`);
      setDeleteStatus(!deleteStatus);
      console.log("page" + page);
      setLen(response.data.totalPages);
      if (len < parseInt(page)) {
        navigate(`/blogs`);
      }
      navigate(`/blogs`);
    } catch (err) {
      console.log(err);
    }
  };

  const addTWL = async (e, id, value) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      const formData = {
        fav: value,
      };
      if (favCount < 12) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else if (favCount >= 12 && value === false) {
        await axiosInstance.patch(`/favUnFav/${id}`, formData);
        setFav(!fav);
      } else {
        alert("Reached max number of pins");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleNavigation = () => {
    sessionStorage.removeItem("location");
  };

  return (
    <div className="blog-card-wrapper">
      {tokenData.token &&
      (tokenData.roleId === "User" ||
        tokenData.roleId === "Admin" ||
        tokenData.roleId === "Guest") ? (
        <NavLink
          to="/protected/addPost"
          className="position-fixed addBlog px-2 py-2 rounded-2 text-dark"
        >
          Add Blogs
        </NavLink>
      ) : (
        <></>
      )}

      <Blogs
        onSearchChange={handleSearchChange}
        setPaginationStat={setPaginationStat}
        draftsMode={draftsMode}
      />
      <div className="d-flex justify-content-center">
        {/* {blogs[0].university} */}
        <div className="blogCards d-grid justify-content-center">
          {blogs.map((blog) => (
            <div
              key={blog.scBlogId}
              className="blog-card m-3  position-relative rounded-3"
            >
              <div className="blogImgWrapper d-flex justify-content-center align-items-center">
                <img
                  className="card-img-top rounded-3 bg-white position-absolute d-flex justify-content-center align-items-center"
                  src={
                    blog.Image === ""
                      ? farmersPlaceholder
                      : `${config.deployUrl}${blog.Image}`
                  } // Update with the image source from your data
                  alt={blog.blogName}
                  loading="lazy"
                />
              </div>
              <div className="onBlogPublishDate px-2 position-absolute end-0 my-3">
                {blog.publishedOn.split("on")[1]}
              </div>

              <div className="card-body blogPreviewContent overflow-hidden">
                <h5 className="card-title" title={blog.blogName}>
                  {blog.blogName && blog.blogName.length > 25
                    ? blog.blogName.slice(0, 25) + "...."
                    : blog.blogName}
                </h5>
                <p>
                  <span className="blogPreview">
                    {blog.preview && blog.preview.length > 113
                      ? blog.preview.slice(0, 113) + "..."
                      : blog.preview}
                  </span>
                  {/* <Link
                    to={`/blog/${blog.blogName}/${blog._id}?page=${currentPage}`}
                    style={{ textDecoration: "none" }}
                  >
                    <span>Read More</span>
                  </Link> */}
                </p>

                {/* <p className="card-text">{university.preview}</p> */}
                {/* <div dangerouslySetInnerHTML={{ __html: university.Data }} /> */}
                <NavLink
                  to={`/blog/${urlEncoded(blog.blogName)}/${blog.scBlogId.slice(
                    4,
                    10
                  )}`}
                  // ?page=${currentPage}
                  className="blogVisitBtn position-absolute text-dark rounded-5  shadow-sm"
                  onClick={handleNavigation}
                >
                  View
                </NavLink>
                {/* <div className="position-absolute end-0 bottom-0 publisedName">
                  <div className="p-2">
                    <strong>@{blog.author}</strong>
                  </div>
                </div> */}
              </div>
              {tokenData.token &&
                tokenData.roleId === "Admin" &&
                (blog.fav === true ? (
                  <GiPin
                    className="pin position-absolute z-1 bg-white"
                    onClick={(e) => addTWL(e, blog.scBlogId, !blog.fav)}
                  />
                ) : (
                  <RxDrawingPin
                    className="pin position-absolute z-1 bg-white"
                    onClick={(e) => addTWL(e, blog.scBlogId, true)}
                  />
                ))}
              {tokenData.token && tokenData.roleId === "Admin" && (
                <MdDelete
                  className="deletePin"
                  onClick={(e) => deleteBlog(e, blog.scBlogId)}
                />
              )}
            </div>
          ))}
        </div>
      </div>

      {pageCount > 1 && (
        <div className="pagination-container">
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination d-flex justify-content-center"}
            activeClassName={"active"}
            forcePage={!draftsMode ? currentPage - 1 : paginNum - 1}
          />
        </div>
      )}
    </div>
  );
}

export default TechBlogs;
