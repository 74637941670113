import React from "react";
import LandingPage from "../Components/Home/Landingpage";
import AboutUs from "../Components/AboutUs/AboutUs";
import Services from "../Components/Services/Services";
import Technologies from "../Components/Technologies/Technologies";
import OurProducts from "../Components/OurProducts/OurProducts";
import WhyChooseUs from "../Components/WhyChooseUs/WhyChooseUs";
import BenefitsOfChoosingUs from "../Components/BenefitsOfChoosing/BenefitsofChoosingUs";
import Faq from "../Components/FAQ/Faq";
import ContactForm from "../Components/ContactForm/ContactForm";
import Clients from "../Components/Clients/Clients";
import HelmetWrapper from "../HelmetWrapper";

function Home() {
  return (
    <>
      <HelmetWrapper
        title="SVSR Communications Pvt Ltd | SVSRCO"
        description="Revolutionizing Businesses through Cutting-Edge IT Solutions and Seamless Development Expertise."
        canonicalUrl="https://www.svsrco.com"
      />
      <section id="home">
        <LandingPage />
      </section>
      <section id="about">
        <AboutUs />
      </section>
      <section id="services">
        <Services />
      </section>
      <Technologies />
      <section id="products">
        <OurProducts />
      </section>
      <section id="support">
        <WhyChooseUs />
      </section>
      <section id="benefits">
        <BenefitsOfChoosingUs />
      </section>
      <section id="clients">
        <Clients />
      </section>
      <section id="faq">
        <Faq />
      </section>
      <section id="contact">
        <ContactForm />
      </section>
    </>
  );
}

export default Home;
