import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Nav, Navbar } from "react-bootstrap";
import Burgermenu from "../Assets/burgerMenu.png";
import { AuthContext } from "../App";
import LOGO from "../Assets/logo.png";
import { FaArrowUp } from "react-icons/fa6";
import config from "../boot/config.json";
import axios from "axios";
import profile from "../Assets/profileImg.jpg";
import "./navbar.css";

function NavbarComponent() {
  const [stickyClass, setStickyClass] = useState("");
  const [scrollToTop, setScrollToTop] = useState("");
  const { tokenData, handleLogout } = useContext(AuthContext);
  const [userData, setUserData] = useState([]);
  const [link, setLink] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [details, setDetails] = useState({
    role: "",
    userId: "",
  });

  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (window.location.hash === "#contact") {
        setLink("contact");
      } else {
        setLink(location.pathname);
      }
    }, 0);
  }, [location.pathname]);

  const scrollToElement = (id) => {
    const element = document.getElementById(id);

    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setLink(id);
  };

  const handleNavigate = (path) => {
    if (path === "/" || path === "/contact") {
      navigate("/");
      window.scrollTo(0, 0);
    } else {
      navigate(path);
      window.scrollTo(0, 0);
    }
    setLink(path);
    setExpanded(false); // Close the dropdown
  };

  useEffect(() => {
    const storedToken = sessionStorage.getItem("svsrJWT");
    if (storedToken) {
      const authData = JSON.parse(storedToken);
      setDetails((prevData) => ({
        ...prevData,
        userId: authData.userId,
        role: authData.roleId,
      }));
    }
  }, [tokenData]);

  useEffect(() => {
    if (details.userId) {
      axiosInstance
        .get(`/User/${details.userId}`)
        .then((response) => {
          setUserData(response.data.data);
        })
        .catch((error) => {
          console.error("There was an error fetching the user data!", error);
        });
    }
  }, [details.userId, details.role, tokenData.profileStatus]);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    const scrollY = document.documentElement.scrollTop;
    const isPageScrolled = scrollY > 100;

    setStickyClass(
      isPageScrolled ? "sticky-nav position-fixed top-0 start-0 bg-white " : ""
    );

    setScrollToTop(isPageScrolled);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      expanded={expanded}
      className={`p-2  d-flex position-relative  ${stickyClass} shadow-sm`}
    >
      <Navbar.Brand>
        <img
          src={LOGO}
          alt="Company Logo"
          className="d-inline-block align-top logo"
          onClick={() => handleNavigate("/")}
        />
      </Navbar.Brand>
      <Navbar.Toggle
        className="custom-toggler border-0"
        onClick={() => setExpanded(!expanded)}
      >
        <img src={Burgermenu} alt="menu" />
      </Navbar.Toggle>
      <Navbar.Collapse
        id="basic-navbar-nav"
        className="justify-content-end mb-navbarC "
      >
        <Nav className="navLinks pb-2 p-lg-4">
          <NavLink
            to="/"
            onClick={() => handleNavigate("/")}
            className={
              link === "/"
                ? "activated text-decoration-none p-2"
                : " text-decoration-none   p-2"
            }
          >
            Home
          </NavLink>
          {/* <Nav.Link
            href="#about"
            onClick={() => handleNavigate("/about")}
            className={link === "/about" ? "activated" : ""}
          >
            About Us
          </Nav.Link>
          <Nav.Link
            href="#services"
            onClick={() => handleNavigate("/services")}
            className={link === "/services" ? "activated" : ""}
          >
            Services
          </Nav.Link>
          <Nav.Link
            href="#products"
            onClick={() => handleNavigate("/products")}
            className={link === "/products" ? "activated" : ""}
          >
            Products
          </Nav.Link>
          <Nav.Link
            href="#benefits"
            onClick={() => handleNavigate("/benefits")}
            className={link === "/benefits" ? "activated" : ""}
          >
            Benefits
          </Nav.Link> */}

          <NavLink
            to="/blogs"
            onClick={() => handleNavigate("/blogs")}
            className={
              link === "/blogs"
                ? "activated text-decoration-none  p-2"
                : "text-decoration-none  p-2 "
            }
          >
            Blogs
          </NavLink>

          {tokenData.token && (tokenData.roleId === "Admin" || "Guest") && (
            <NavLink
              to="/protected/myBlogs"
              onClick={() => handleNavigate("/protected/myBlogs")}
              className={
                link === "/protected/myBlogs"
                  ? "activated text-decoration-none p-2"
                  : "text-decoration-none p-2"
              }
            >
              myBlogs
            </NavLink>
          )}

          {tokenData.token && tokenData.roleId === "Admin" && (
            <NavLink
              to="/protected/Users"
              onClick={() => handleNavigate("/protected/Users")}
              className={
                link === "/protected/Users"
                  ? "activated text-decoration-none p-2"
                  : "text-decoration-none p-2"
              }
            >
              Users
            </NavLink>
          )}
          <NavLink
            to="/careers"
            onClick={() => {
              handleNavigate("/careers");
            }}
            className={
              link === "/careers"
                ? "activated text-decoration-none p-2"
                : " text-decoration-none   p-2"
            }
          >
            Jobs
          </NavLink>
          <a
            href="#contact"
            onClick={(e) => {
              navigate("/");
              scrollToElement("contact"); // Scroll to the element with ID 'contact'
            }}
            className={
              link === "contact"
                ? "activated text-decoration-none p-2"
                : "text-decoration-none p-2"
            }
          >
            Contact
          </a>
          {tokenData.token && (
            <NavLink
              to="/"
              className="text-decoration-none p-2"
              onClick={() => {
                handleLogout();
              }}
            >
              Logout
            </NavLink>
          )}
          <div className=" d-flex flex-column justify-content-center ">
            {tokenData.token && (
              <NavLink
                to={`/protected/UserProfile/${details.userId}`}
                title="profile"
                className="text-decoration-none  text-center profileImg rounded-circle d-flex flex-column justify-content-center align-items-center"
                onClick={() =>
                  navigate(`/protected/UserProfile/${details.userId}`)
                }
              >
                <img
                  src={
                    userData.profile === ""
                      ? profile
                      : `${config.deployUrl}${userData.profile}`
                  }
                  alt={userData.name}
                  className="profileImg rounded-circle"
                  loading="lazy"
                />
              </NavLink>
            )}
          </div>
        </Nav>
      </Navbar.Collapse>

      {/* <a
        className={`scrollToTop position-fixed bottom-0 end-0 text-white fs-5 justify-content-center align-items-center rounded-circle m-2${
          scrollToTop ? " d-flex" : " d-none"
        }`}
        href="#home"
        onClick={() => navigate("/")}
      >
        <FaArrowUp />
      </a> */}
    </Navbar>
  );
}

export default NavbarComponent;
