import React, { useState, useContext } from "react";
import itBlogs from "../../../Assets/itBlogs.png";
import "./blogs.css";
import { AuthContext } from "../../../App";
import { RxCross2 } from "react-icons/rx";
import HelmetWrapper from "../../../HelmetWrapper";

function Blogs({ onSearchChange, draftsMode, setPaginationStat }) {
  const [search, setSearch] = useState("");
  const { tokenData, setTokenData } = useContext(AuthContext);

  function handleChange(e) {
    const { id, value } = e.target;
    if (id == "search") {
      setSearch(value);
      setTokenData((prevData) => ({
        ...prevData,
        blogSearch: value,
      }));
      // onSearchChange(value);
    }
  }

  const handleSearchStat = (e) => {
    e.preventDefault();
    setSearch("");
    setTokenData((prevData) => ({
      ...prevData,
      blogSearch: "",
    }));

    // Use setTimeout to call handleSearchBlog after state update
    setTimeout(() => {
      handleSearchBlog(e, true);
    }, 0);
  };

  const handleSearchBlog = (e, isClear = false) => {
    e.preventDefault();
    if (!isClear) {
      setTokenData((prevData) => ({
        ...prevData,
        blogSearch: search,
      }));
    }
    setPaginationStat(false);
    onSearchChange(isClear ? "" : search);
  };

  return (
    <div className="Blogs">
      <HelmetWrapper
        title="SVSR Articles | Delivering Comprehensive Tech Knowledge to Keep You
          Ahead in IT"
        description="Expand You Knowledge and keep up to date with latest tech. SVSR is revolutionizing Businesses through Cutting-Edge IT Solutions and Seamless Development Expertise."
        canonicalUrl="https://www.svsrco.com/blogs"
      />

      <div className="blogsWrapper">
        <div className="d-flex justify-content-center h-100">
          <div className="blogSearch d-flex flex-column justify-content-center align-items-start">
            <div className="text-white blogsPhrase d-flex align-items-center">
              Delivering Comprehensive Tech Knowledge to Keep You Ahead in IT
            </div>
            <div>
              {!draftsMode && (
                <div className="searchBar d-flex justify-content-center">
                  <form
                    action=""
                    onSubmit={handleSearchBlog}
                    className="d-flex"
                  >
                    <div className="position-relative ">
                      <input
                        type="text"
                        placeholder="Search Blogs...."
                        className="border-0"
                        id="search"
                        value={tokenData.blogSearch}
                        onChange={(e) => handleChange(e)}
                      />
                      {tokenData.blogSearch &&
                        tokenData.blogSearch.length > 0 && (
                          <RxCross2
                            className="position-absolute  crossSearch rounded-circle  p-1"
                            onClick={(e) => handleSearchStat(e)}
                          />
                        )}
                    </div>
                    <button className="border-0 searchBlogBtn text-white">
                      Search
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
          <div className="BlogPotImg d-flex flex-column justify-content-center align-items-center">
            <img
              src={itBlogs}
              loading="lazy"
              alt="pot plant"
              className="blogsPotPlant"
            />
            {!draftsMode ? (
              <>
                <h1 className="text-white">BLOGS</h1>
              </>
            ) : (
              <>
                <h1 className="text-white">DRAFTS</h1>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blogs;
