import { useEffect, useState } from "react";

function AlertDismissible({ message, value }) {
  const [alertValue, setAlert] = useState(true);

  useEffect(() => {
    if (value == false || value == undefined) {
      setAlert(false);
    }
  }, [value]);

  return (
    <div
      className={`shadow-lg d-flex justify-content-center align-items-center pt-3 text-white px-3 rounded-2 position-relative ${
        alertValue ? "positive" : "negative"
      } `}
    >
      <p>{message}</p>
    </div>
  );
}

export default AlertDismissible;
