import React, { useState, useContext, useRef } from "react";
import { AuthContext } from "../../App";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../../boot/config.json";
import { FaArrowLeftLong } from "react-icons/fa6";
import "./addUser.css";

const AddUser = () => {
  const [formData, setFormData] = useState({
    name: "",
    agencyName: "",
    email: "",
    phone: "",
    role: "Guest",
    password: "",
    confirmPassword: "",
    error: "",
  });
  const navigation = useNavigate();
  const location = useLocation();

  const { tokenData, setTokenData } = useContext(AuthContext);
  const axiosInstance = axios.create({
    baseURL: `${config.baseUrl}/Users`,
    headers: {
      Authorization: tokenData.token,
    },
  });

  function alertMsg(messages, alertStatus) {
    setTokenData((prevState) => ({
      ...prevState,
      alertMsg: messages,
      alertValue: true,
      alertStatus,
    }));
  }
  const imageInputRef = useRef(null); // Create a ref for the file input

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone") {
      const numericOnly = value.replace(/\D/g, "");
      const truncatedValue = numericOnly.slice(0, 10);
      setFormData((prevFD) => ({ ...prevFD, [name]: truncatedValue }));
    } else {
      setFormData((prevFD) => ({ ...prevFD, [name]: value }));
    }
  };

  const handleFileChange = (e) => {
    setFormData((prevFD) => ({ ...prevFD, image: e.target.files[0] }));
  };
  const handleBackClick = () => {
    if (location.state && location.state.from) {
      navigation(`/${location.state.from}`);
    } else {
      navigation(-1); // This will go back to the last page in history
      // navigate(-1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // const data = new FormData();
    // for (const key in formData) {
    //   data.append(key, formData[key]);
    // }

    try {
      // console.log("data", data);
      const response = await axiosInstance.post("/Registration", formData);
      alertMsg("User Added successfully", true);
      setTimeout(() => {
        setTokenData((prevState) => ({ ...prevState, alertValue: false }));
      }, 1500);
      setFormData({
        name: "",
        email: "",
        phone: "",
        role: "Guest",
      });
      navigation("/protected/Users");
      // imageInputRef.current.value = null; // Clear the file input using the ref
    } catch (error) {
      console.log("error response", error.response);
      console.error("Error submitting form", error);
      if (error.response.data === "User already registered") {
        alertMsg("User already registered", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 1500);
      } else {
        alertMsg("Failed to submit form", false);
        setTimeout(() => {
          setTokenData((prevState) => ({ ...prevState, alertValue: false }));
        }, 1500);
      }
    }
  };

  return (
    <div>
      <button
        className="position-fixed start-0 mx-3 rounded-1 indAgencyBackBtn"
        onClick={() => handleBackClick()}
      >
        <FaArrowLeftLong className="text-white" />
      </button>
      <form className="responsive-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">
            Name <sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">
            Email <sup className="text-danger">*</sup>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="phone">
            Phone <sup className="text-danger">*</sup>
          </label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="userType">
            Role <sup className="text-danger">*</sup>
          </label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleChange}
            required
          >
            <option value="Guest">Guest User</option>
            {/* <option value="User">Agency Representative</option> */}
          </select>
        </div>
        {formData.role === "User" && (
          <div className="form-group">
            <label htmlFor="agencyName">
              Agency Name <sup className="text-danger">*</sup>
            </label>
            <input
              type="text"
              id="agencyName"
              name="agencyName"
              value={formData.agencyName}
              onChange={handleChange}
              required
            />
          </div>
        )}
        {/* <div className="form-group">
          <label htmlFor="password">
            Password <sup className="text-danger">*</sup>
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">
            Confirm Password <sup className="text-danger">*</sup>
          </label>
          <input
            type="password"
            id="confirmPassword"
            name="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">
            Profile Picture <sup className="text-danger">* </sup>
            <span className="ms-2">
              <b>JPG,PNG</b>
            </span>
          </label>
          <input
            type="file"
            id="image"
            name="image"
            accept="image/jpeg,image/png"
            capture="user" // Change to "user" for the front camera
            onChange={handleFileChange}
            ref={imageInputRef} // Attach the ref to the file input
            required
          />
        </div> */}
        <button type="submit" className="text-white">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddUser;
