import React, { useState, useEffect, useContext } from "react";
import "./careerDescription.css";
import { FaPencilAlt } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import JobEditor from "../JobEditor/JobEditor";
import { useParams, Link } from "react-router-dom";
import { AuthContext } from "../../App";
import axios from "axios";
import config from "../../boot/config.json";
import HelmetWrapper from "../../HelmetWrapper";

function CareerDescription() {
  const [editMode, setEditMode] = useState(false); // State to manage edit mode
  const { jobId, title } = useParams();
  const [JobData, setJobData] = useState([]);

  function draftModeEdit(value) {
    return setEditMode(value);
  }

  const handleBackClick = () => {
    setEditMode(false);
  };

  const { tokenData } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        `${config.baseUrl}/Jobs/getJob/${jobId}`
      );
      setJobData(response.data);
    };
    fetchData();
  }, [jobId, editMode]);

  function urlEncoded(title) {
    return title
      .toLowerCase() // Optional: convert to lowercase
      .replace(/[^a-z0-9]+/g, "-") // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ""); // Remove leading and trailing hyphens
  }

  const handleEditClick = () => {
    setEditMode(true); // Set edit mode to true when edit icon is clicked
  };
  if (!JobData[0]) {
    return <p>Job not found</p>;
  }
  return (
    <section className="bg-white">
      <HelmetWrapper
        title={
          JobData[0].title && JobData[0].title.length > 0
            ? JobData[0].title
            : "Professional Opportunities | SVSRCO"
        }
        description={
          JobData[0].preview && JobData[0].preview.length > 0
            ? JobData[0].preview
            : "Discover tech job opportunities with SVSR Communications."
        }
        canonicalUrl={`https://www.svsrco.com/careers/${title}/${jobId}`}
      />
      <div>
        {!editMode && (
          <Link
            to="/careers"
            className="careerback text-decoration-none position-fixed text-center px-2 py-1"
          >
            <FaArrowLeftLong />
          </Link>
        )}
        {!editMode &&
          tokenData.token &&
          tokenData.roleId === "Admin" && ( // Render edit button and job description if not in edit mode
            <>
              <button
                onClick={handleEditClick}
                className="editPost text-decoration-none text-white bg-dark float-end position-relative text-center"
              >
                <FaPencilAlt />
              </button>
            </>
          )}
      </div>

      {editMode ? (
        <>
          <button
            onClick={handleBackClick}
            className="careerback position-fixed px-2 py-1"
          >
            <FaArrowLeftLong />
          </button>
          <JobEditor
            editTitle={JobData[0].title}
            editPreview={JobData[0].preview}
            editJobDescription={JobData[0].jobDescription}
            handleDraftMode={draftModeEdit}
          />
        </>
      ) : (
        <div className="jobDescription d-flex flex-column justify-content-center">
          <h2 className="jobHeading">{JobData[0].title}</h2>
          <p dangerouslySetInnerHTML={{ __html: JobData[0].jobDescription }} />
          <Link
            to={`/applylink/${urlEncoded(title)}/${jobId}`}
            className="applyButton my-3 text-decoration-none text-white bg-dark text-center"
          >
            Apply Now
          </Link>
        </div>
      )}
    </section>
  );
}

export default CareerDescription;
